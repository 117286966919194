/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { WorldsWebModels } from '@/store/worldsWebModels/types'
const namespace: string = 'worldsWebModels';


export default class WorldsWebModelsVideosBigSizePage extends Vue {
	@State('worldsWebModels') worldsWebModels: WorldsWebModels | undefined;
	@Getter('getModelsVideo', {namespace}) getModelsVideo: any;
	@Getter('getItemVideoNumber', {namespace}) getItemVideoNumber: any;

	item: number = 0;
	length: number = 0;

	toSwitchPhoto(value: string) {
		this.item = this.getItemVideoNumber;
		switch(value) {
		case 'prev':
			this.item > 0 ? this.item-- : false;
			break;
		case 'next':
			this.item < this.getModelsVideo.length-1 ? this.item++ : false;
			break;
		}
	}

	closeSlider(){
		this.$router.push({ name: 'worlds-web-models-videos' }).then(r => r);
	}
}
